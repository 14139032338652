import { AxiosResponse } from "axios";
import axios from "../../common/utils/axios";

interface LoginAPIParams {
  email: string;
  password: string;
}

interface LoginAPIResponse {
  message: string;
  token: string;
}

interface RegisterAPIParams {
  fullName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

export const signinAPI = (email: string, password: string) => {
  return axios.post<LoginAPIParams, AxiosResponse<LoginAPIResponse>>(`/login`, {
    email,
    password,
  });
};

export const signupAPI = (
  fullName: string,
  email: string,
  password: string,
  passwordConfirmation: string
) => {
  return axios.post<RegisterAPIParams>(`/register`, {
    fullName,
    email,
    password,
    passwordConfirmation,
  });
};
